.container {
  width: fit-content;
  display: flex;
  justify-content: center;
  align-items: center;
}

.menuIcon {
  cursor: pointer;
  margin-left: 0.3em;
  margin-right: 0.3em;
}

.popoverTitle {
  text-align: center;
}

.popoverContent {
  height: 100%;
}

.childrenContainer {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}

.childrenContainer > button {
  margin-bottom: 0.3em;
}
