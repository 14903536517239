.container {
  margin-bottom: 6em;
}

.headerContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 1em;
}

.previewMembershipContainer {
  font-size: 1.4em;
}

.editMedlemskapBtn {
  margin-right: 0em;
}

.previewMembershipContainer {
  padding-bottom: 1.5em;
}

.knytningBtnIcon {
  margin-right: 0.2em;
}
