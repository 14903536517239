.card {
  margin-bottom: 10px;
}

.btnToolbar {
  float: right;
}

.kjedeDataRow {
  display: flex;
  display: -webkit-flex;
  flex-wrap: wrap;
}

.endreKjedeRow {
  display: flex;
  display: -webkit-flex;
  flex-wrap: wrap;
  width: 100%;
  padding-top: 0em;
  margin: 0;
}

.regionalCol {
  display: flex;
  display: -webkit-flex;
  flex-wrap: wrap;
  width: 100%;
  padding-bottom: 1em;
}

.salgOgMarkedCol {
  display: flex;
  display: -webkit-flex;
  flex-wrap: wrap;
  width: 100%;
  padding-bottom: 1em;
}

.colContentContainer {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.endreBtnContainer {
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  padding-top: 2em;
  padding-left: 1em;
  padding-bottom: 0em;
  height: 10%;
  width: 100%;
}

.endreKjedeMedlemskapContainer {
  height: 100%;
  width: 100%;
  margin: 0;
}

.kjedeTypeHeader {
  padding-left: 0.6em;
}

.endreBtn {
  float: right;
  display: flex;
  align-items: center;
}

.medlemskapContainer {
  height: 100%;
  width: 100%;
  /* border: 1px solid gray;
  border-radius: 5px; */
  padding-bottom: 0em;
}

.medlemskapsTree {
  height: 100%;
  width: 100%;
  padding: 1em;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.historikkContainer {
  height: 100%;
  width: 100%;
  padding-top: 5em;
}

.endreKjedeFormContainer {
  width: 100%;
  height: 100%;
  padding: 1em;
  background-color: #f7f7f7;
  border-top: 1px solid lightgrey;
  border-bottom: 1px solid lightgrey;
}

.lagreMedlemskapButton {
  width: 100%;
}

.medlemskapCol {
  display: flex;
  flex-direction: row;
  height: 100%;
  width: 100%;
  justify-content: center;
}

.treeHeader {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-bottom: 1em;
}

.treeHeaderText {
  margin: 0;
}

.treeContainer {
  width: 100%;
  height: 250px;
}

.contentRow {
  margin: 0 auto;
  width: 100%;
  padding-bottom: 1rem;
}

.historikkHeader {
  padding-top: 2em;
  padding-bottom: 1em;
}

.loader {
  display: flex;
  justify-content: center;
  align-content: center;
}
