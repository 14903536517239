ul.breadcrumb,
ul.breadcrumbsub {
  margin-bottom: 2em;
  padding-left: 0;
  list-style: none;
  /* background-color: #eee; */
  margin: 0 auto;
}

ul.breadcrumbsub {
  padding-left: 0;
}

/* Display list items side by side */
ul.breadcrumb li {
  display: inline;
  font-size: 18px;
  color: rgba(var(--bs-success-rgb));
  text-decoration: none;
}

ul.breadcrumbsub li {
  display: inline;
  font-size: 0.8rem;
  color: gray;
  text-decoration: none;
}

/* Add a slash symbol (/) before/behind each list item */
ul.breadcrumb li + li:before {
  padding: 6px;
  color: black;
  content: '/\00a0';
}

ul.breadcrumb li:last-child {
  color: rgba(var(--bs-success-rgb));
}

ul.breadcrumbsub li + li:before {
  padding: 6px;
  color: black;
  content: '/\00a0';
}

ul.breadcrumbsub li:last-child {
  color: rgba(var(--bs-success-rgb));
}

.endreKundenummerOgPeriodeContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}
.kundeNummerForm {
  width: 100%;
}

.kundeNummerHeader {
  width: 100%;
}
