.basisFormContainer {
  width: 100%;
}

.changeRequestRowHeaderContainer {
  display: flex;
  cursor: pointer;
  /* width: fit-content; */
}

.changeRequestRowHeaderContainer > button {
  padding-top: 0.125rem;
}

.changeRequestRowHeader {
  display: flex;
  padding-top: 0.3em;
  margin: 0;
}

.changeRequestRowHeaderText {
  padding-left: 0.2em;
}

.isNotExpandedRowContainer {
  padding-bottom: 1em;
}

.formGroupRow {
  display: flex;
  /* flex-direction: row; */
  justify-content: center;
  margin: 0 auto;
  padding-bottom: 1em;
}

.formGroupContainer {
  flex: 5;
  padding-left: 0;
}

.formGroup {
  margin: 0 auto;
}

.formControl,
.formControl:focus {
  border-right: 0px;
  box-shadow: none;
}

.compareIconContainer {
  display: flex;
  flex: 1;
  justify-content: center;
}

.orgNrInputGroup {
  margin: 3px !important;
}

.orgNrFormRow {
  margin: 0 auto;
}

.formTxtCol {
  padding-left: 5px;
}

.jurNameMismatchIconContainer {
  padding-left: 0.2em;
}
