.statRow {
    width: 100%;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
}
  
.statCol {
    max-width: fit-content;
    padding: 1em;
    margin-right: 1em;
    margin-left: 1em;
    margin-bottom: 1em;
    border: 1px solid gray;
    border-radius: 10px;
}

.statusStats {
    display: flex;
    justify-content: space-around;
}

.statContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
  
.statHeader {
    font-size: 2em;
    font-weight: bold;
    margin: 0 auto;
}
  
.statNameHeader {
    font-size: 1.6em;
}

@media screen and (max-width: 950px) {
    .statRow {
        width: 90%;
    }
}