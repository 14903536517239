.endreKjedeFormContainer {
  width: 100%;
  height: 100%;
}

.suggestionContainer {
  padding-top: 2em;
  padding-bottom: 2em;
}

.suggestion {
  border: 2px solid rgba(var(--bs-success-rgb));
  border-radius: 5px;
}

.endreHeader {
  padding-top: 1em;
}

.kjedePlaceholder {
  padding: 3em;
  display: flex;
  justify-content: center;
  align-items: center;
}

.pickedKjedeContainer {
  width: 100%;
  height: 100%;
  padding: 3em;
}

.suggestionContainer {
  padding-top: 0;
  padding-bottom: 2em;
}

.betraktesSomKjedemedlemskapFormContainer {
  padding-top: 2em;
}

.betraktesSomKjedemedlemskapOptions {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
