.popover {
  max-width: 1000px !important;
}

.popoverContent {
  width: fit-content;
}

.popoverContentCol {
  width: 350px;
}