.sortByTagContainer {
  padding: 0em;
  margin-bottom: 0.5em;
  display: flex;
  justify-content: space-evenly;
  align-items: baseline;
  flex-wrap: wrap;
}

.customToggleBtn {
  font-size: 12px;
  align-items: center;
  border-radius: 30px;
  margin: 0.2em;
  display: flex;
  padding: 0.5em;
}

.customToggleBtn>div {
  line-height: 0;
}

.labelTxt {
  padding-left: 0.5em;
  padding-right: 0.5em;
}