.container {
  display: flex;
  justify-content: flex-start;
  align-items: inherit;
}

.statusBadge {
  margin-left: 0.3em;
}

.kjedeName {
  margin-bottom: 0;
}
