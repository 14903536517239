.spinnerBtn {
  display: flex;
  justify-content: center;
  align-items: center;
}

.linkToEnhetBtn {
  font-size: 1em;
  padding-left: 0.2em;
}
