.listItem {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.closeMembershipBtn {
  margin-left: 0.4em;
}

.membershipContainer {
  display: flex;
  flex-direction: column;
}

.membership {
  font-size: 1.4em;
}

.goToKjedeLinkBtn {
  padding-right: 0;
  padding-left: 0;
}

.deleteHistoryBtn {
  height: 100%;
}

.hideDeleteHistoryBtn {
  visibility: hidden;
}
