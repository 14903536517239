.selectedChangeRequestCard {
    border: 4px solid rgba(var(--bs-dark-rgb));
    font-weight: bold;
    width: 100%;
    color: black;
}

.changeRequestCard {
    border: 2px solid rgba(var(--bs-secondary-rgb));
    font-weight: none;
    width: 100%;
    color: black;
}

.cardHeader {
    width: 100%;
    padding: 1rem;
    align-items: center;
}

.cardHeaderTitle {
    margin: 0;
    font-size: 1.1em;
    font-weight: bold;
    float: left;
}

.optionsContainer {
    margin: 0 auto;
    width: fit-content;
    float: right;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.cardStatusContainer {
    width: fit-content;
    float: right;
    display: flex;
    align-items: center;
    padding-right: 1em;
}

.cardFooter {
    padding: 0.3rem;
    margin: 0;
}

.cardFooterTextContainer {
    display: flex;
    justify-content: space-between;
    padding: 0;
    align-items: center;
}

.cardFooterText {
    margin: 0;
}

.cardBodyStatusMessageContainer {
    display: flex;
    align-items: center;
}

.cardBodyStatusMessage {
    margin: 0;
    padding: 0.5em;
    font-size: 1.1em;
}

.cardBodyValidationMessageContainer {
    display: flex;
    align-items: center;
}

.cardBodyValidationMessage {
    margin: 0;
    padding: 0.5em;
    font-size: 1.1em;
}

.qaCard {
    margin: 0.1em 0;
    border-color: #6c757d;
    border-width: 2px;

    &:hover {
        background-color: var(--bs-primary-bg-subtle);
    }
}

.qaCardSelected {
    margin: 0.1em 0;
    border-color: #6c757d;
    border-width: 3px;
    background-color: var(--bs-primary-bg-subtle) !important;
}