.footerContainer {
  position: relative;
  height: 50px;
  padding-bottom: 0.5em;
  padding-left: 1.5em;
  padding-right: 1.5em;
  margin-left: 0;
  margin-right: 0;
  margin-bottom: 0;
  background-color: #efefef;
  vertical-align: middle;
  border-top: 1px solid black;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  -webkit-box-shadow: 0px 11px 12px -10px rgba(0, 0, 0, 0.48);
  box-shadow: 0px 11px 12px -10px rgba(0, 0, 0, 0.48);
}

.actionBtnToolbar {
  padding-top: 0.5em;
  display: flex;
  justify-content: space-between;
}