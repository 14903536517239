.isLoadingContainer {
  height: 50vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.tabContainer {
  overflow-y: scroll;
  height: 90vh;
}

.plusIcon {
  margin-top: -4px;
}