.createGrossistContainer {
  padding-top: 1.5em;
}

.opprettBtnRow {
  padding-bottom: 2em;
}

.opprettBtnCol {
  width: 100%;
}

.formLabel {
  padding-right: 1em;
}
