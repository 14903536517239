.listgroupRow {
  margin: 0 auto;
  padding-bottom: 2em;
}

.listGroupItem {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.copyContainer {
  display: flex;
  align-items: center;
}

.legendCol {
  display: flex;
}

.typerItem {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.actionIcons {
  width: 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

/* .expandableListItemContainer {
  display: flex;
  align-items: center;
} */

.expandableItemContent {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.expandableItemShowMoreLinkBtn {
  text-decoration: none;
  margin: 0;
  text-align: center;
  padding-left: 0.5em;
  padding-bottom: 0.1em;
}

.statusBadge {
  margin-left: 0.3em;
}

.itemValue {
  margin-bottom: 0;
}

.content {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.historicListgroupItem {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
