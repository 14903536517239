.card {
  height: 100%;
  width: 100%;
  padding: 1em;
  margin-bottom: 3em;
}

.cardBody {
  padding: 0em;
}

.cardCurrentTitle {
  display: flex;
  padding-right: 0.5em;
  padding-left: 0.5em;
  padding-top: 0.5em;
  padding-bottom: 1.5em;
  margin: 0;
  justify-content: space-between;
  /*   color: var(--success); */
}

.editMedlemskapBtn {
  padding-left: 0.5em;
}

.editGrupperingForm {
  margin: 0 auto;
  padding: 1.5em;
}

.submitBtnCol {
  display: flex;
  justify-content: flex-end;
}

.titleContainer {
  display: flex;
  align-items: center;
}

.infoTag,
.activeTag {
  padding-right: 0.5em;
  margin-bottom: 0;
}

.activeTag {
  color: rgba(var(--bs-success-rgb));
}
.infoTag {
  color: rgba(var(--bs-warning-rgb));
}
