.container {
    border: 1px solid black;
    border-radius: 5px;
    height: calc(100vh - 80px);
    overflow-y: auto;
}

.qaDetailContainer {
    width: 100%;
    padding: 2em;
    margin: 0 auto;
    overflow-y: auto;
    background-color: #fff;
}

.formFieldRow {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    padding-bottom: 1em;
}

.changeRequestStatusMsg {
    color: orange;
    text-align: center;
}

.spinnerContainer {
    top: 0;
    height: calc(100vh - 370px);
    width: 100%;
    margin-top: 20px;
}