.container {
  margin-top: 1em;
}
.knytningSuggestionListItem {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.enhetgrupperingnavn {
  font-size: 0.875rem;
  color: rgba(var(--bs-success-rgb));
}
.dashSeperator {
  font-size: 0.875rem;
}

.checkbox {
  vertical-align: middle;
}

.undergrupperingListGroup {
  max-height: 350px;
  overflow-y: scroll;
}
