.suggestionContainer {
  width: 50%;
  padding-bottom: 2em;
}

.selectedGrupperingFormListGroup {
  padding: 0em;
}

.selectedGrupperingFormListGroupItem {
  padding: 2em;
}
.kjedemedlemskapCol {
  width: fit-content;
}
