.secondLabel {
  padding-top: 0.75em;
}

.checkbox {
  padding-top: 0.75em;
  display: flex;
  column-gap: 10px;
}

.formText{
  background-color: #fff3cd;
}