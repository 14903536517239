.addressContainer {
  margin: 0 auto;
  margin-bottom: 2.5em;
}

.inputGroup {
  margin-bottom: 0 !important;
  min-width: 50%;
  width: 60%;
  padding-right: 15px;
}

@media screen and (min-width: 1200px) and (max-width: 1720px) {
  .inputGroup {
    width: 100%;
  }
}
.buttonError {
  border-top: 1px red solid;
  border-bottom: 1px red solid;
  border-left: 1px red solid;
}

.addressOptions {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.optionsContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.detailBtn {
  margin-left: 1em;
  min-width: 110px;
  width: auto;
}

.adresseTooltip > .inner-tooltip {
  max-width: 400px;
}
