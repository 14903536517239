
.juridiskFormLabel {
  margin-left: 0.5em;
}

.brregWarningFormText {
  color: rgba(var(--bs-warning-rgb));
}

.brregErrorFormText {
  color: rgba(var(--bs-danger-rgb));
  font-weight: bold;
}
