.isLoadingContainer {
  height: 50vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.icon {
  margin-left: 1rem;
}

.desc {
  font-size: 13px;
}