.container {
  /*   height: 100%; */
  overflow-y: hidden;
  overflow-x: hidden;
  background-color: #f3f3f3;
}

.notSelectedChangeRequestContainer {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.notSelectedChangeRequestInfo {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.notSelectedChangeRequestHeader {
  padding-bottom: 1.5em;
}

.notSelectedChangeRequestIcon {
  width: 100%;
  justify-content: center;
  align-items: center;
}

.statsHeader {
  display: flex;
  justify-content: center;
}

.filterLinkBtn {
  position: relative;
  bottom: 0;
  align-self: center;
}