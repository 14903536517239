.popover {
  width: 100%;
  height: fit-content;
  padding-bottom: 3em;
}

.btnContainer {
  position: absolute;
  margin: auto;
  right: 1em;
  bottom: 1em;
}

.duplicateContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.formGroup {
  margin-bottom: 0.5em;
}
