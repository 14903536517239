.container {
  padding: 1em;
  height: 100%;
}

.eierAvGlnRow {
  padding-bottom: 1em;
}

.removeGlnRow {
  padding-top: 0.5em;
}

.orderGlnRow {
  padding-top: 1em;
}

.goToOwnerOfGlnBtn {
  padding-left: 0;
  color: green;
}

.goToOwnerOfGlnBtnOnFetching {
  padding-left: 0;
  color: none;
}
