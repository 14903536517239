.radioBtnContainer {
  display: flex;
  flex-direction: column;
}

.modalFooter {
  display: flex;
}

.toppKjedeModalFotter {
  justify-content: space-between;
}

.notToppKjedeModalFotter {
  justify-content: flex-end;
}

.closeModalBtn {
  margin-right: 0.3em;
}

.refreshKjedeEnhetIcon {
  margin-left: 0.3em;
}
