.inputAppendIcon {
  cursor: pointer;
  background-color: #ffffff;
  border-left: none;
}

.spinnerContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 1px solid lightgrey;
  height: 920px;
}
