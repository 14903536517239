.Highlight {
  background-color: #1abc9c;
  color: #ecf0f1;
  padding-top: 0.4rem;
  padding-bottom: 0.4rem;
  margin-left: -0.1rem;
  margin-right: -0.1rem;
}

.NodeButton {
  height: 100%;
  width: 100%;
  padding-top: 0.2rem;
  padding-bottom: 0.2rem;
  padding-left: 1rem;
  padding-right: 1rem;
  box-shadow: 0 4px 4px -4px;
  background-color: white;
}
