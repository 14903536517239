
.nyGrossistContainer {
  padding-top: 1.5em;
}

.formLabel {
  padding-right: 1em;
}

.opprettBtnRow {
  padding-bottom: 2em;
  padding-top: 1em;
  display: inline-flex;
}

.valgtEnhetFromSearchListGroupRow h6 {
  padding-top: 0.5em;
}
