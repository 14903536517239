.isLoadingSpinner{
    padding-right: 1em;
}
  
.buttonStyle{
   margin-right: 5px;
}
  
.moveButton{
    float: right;
}