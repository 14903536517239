.addressFormContainer {
  width: 100%;
  padding-bottom: 1em;
}

.changeRequestRowHeaderContainer {
  display: flex;
  cursor: pointer;
  width: fit-content;
}

.changeRequestRowHeaderContainer > button {
  padding-top: 0.125rem;
}

.isNotExpandedRowContainer {
  padding-bottom: 1em;
}

.changeRequestRowHeader {
  display: flex;
  padding-top: 0.3em;
  margin: 0;
}

.changeRequestRowHeaderText {
  padding-left: 0.2em;
}

.addressFormRow {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: 0 auto;
  padding-bottom: 1em;
}

.formBesoksadresseContainer {
  flex: 5;
  margin: 0 auto;
  padding-left: 0;
}

.formGroup {
  margin: 0 auto;
}

.compareIconContainer {
  display: flex;
  flex: 1;
  justify-content: center;
}

.showMapBtn {
  padding-left: 0;
  padding-bottom: 0;
  padding-top: 0;
}

.addressMapsRow {
  height: 500px;
  padding-bottom: 8em;
}

.checkbox {
  font-size: 14px;
}

.checkbox > input {
  height: 14px;
  width: 14px;
  cursor: pointer;
}

.checkbox > label {
  cursor: pointer;
}