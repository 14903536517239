#tabContainer {
  height: 100%;
}

.tabNav {
  padding-bottom: 1.2em;
  margin: 0 auto;
  cursor: pointer;
}

.tabContent {
  overflow: auto;
}

.tabPane {
  height: calc(100vh - 320px);
  overflow-y: auto;
  overflow-x: hidden;
  padding: 1em;
  /*  
  border: 1px solid lightgray;
  border-radius: 5px; */
}
