.main {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.statRow {
  width: 100%;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

.statCol {
  max-width: 380px;
  padding: 1em;
  margin-right: 1em;
  margin-left: 1em;
  margin-bottom: 1em;
  border: 1px solid gray;
  border-radius: 10px;
}

.last12Stats,
.statusStats {
  display: flex;
  justify-content: space-around;
}

.statContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.statHeader {
  font-size: 2em;
  font-weight: bold;
  margin: 0 auto;
}

.statNameHeader {
  font-size: 1.6em;
}

.datePicker {
  display: flex;
  flex-direction: row;
  width: fit-content;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
}