ul.breadcrumb {
  margin-bottom: 2em;
  padding-left: 1em;
  list-style: none;
  /* background-color: #eee; */
  margin: 0 auto;
}

/* Display list items side by side */
ul.breadcrumb li {
  display: inline;
  font-size: 18px;
  color: rgba(var(--bs-primary-rgb));
  text-decoration: none;
}

/* Add a slash symbol (/) before/behind each list item */
ul.breadcrumb li + li:before {
  padding: 8px;
  color: black;
  content: '/\00a0';
}

ul.breadcrumb li:last-child {
  color: rgba(var(--bs-success-rgb));
}
