.button {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  text-align: center;
  align-items: center;
}

.btntxt {
  padding-right: 0.3em;
  padding-left: 0.3;
}

.btnContainer {
  width: 100%;
}
