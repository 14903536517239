.container {
  width: 100%;
}

.formGroup {
  margin: 0;
}

.notatContainer {
  display: flex;
  align-items: center;
  cursor: pointer;
  width: fit-content;
}

.collapsedNotatContainer {
  padding-bottom: 1em;
}

.notatHeader {
  display: flex;
  margin: 0;
}