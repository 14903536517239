.spinnerContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 1px solid lightgrey;
  height: 920px;
}

.spinnerContainerEgDetaljer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 1px solid lightgrey;
  height: 90vh;
  width: auto;
}

.closeResetBtnRow {
  padding-bottom: 1em;
}

.closeResetBtn {
  float: right;
}

.opprettBtnRow {
  padding-top: 0.5em;
  margin: 0;
}

.contentRow {
  padding-top: 1em;
}
