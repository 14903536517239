.headerContainer {
    position: relative;
    max-width: 100%;
    width: 100%;
    padding-top: 0.3em;
    padding-bottom: 0.3em;
    padding-left: 1.0em;
    padding-right: 1.0em;
    margin-left: 0;
    margin-right: 0;
    background-color: #efefef;
    vertical-align: middle;
    border-bottom: 1px solid black;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    -webkit-box-shadow: 0px 11px 12px -10px rgba(0, 0, 0, 0.48);
    box-shadow: 0px 11px 12px -10px rgba(0, 0, 0, 0.48);
}

.enhetSearchContainer,
.infoContainer {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;
}

.headerInfoText {
    float: left;
    display: flex;
    flex-direction: column;
}

.folgesOppContainer {
  padding-bottom: 0.3em;
}

.btnGroupCol,
.closeIconCol {
  display: flex;
  justify-content: flex-end;
}

.windowCloseIcon {
  float: right;
}

.windowCloseIcon:hover {
  text-shadow: 0 0 3px #ff0000;
  transition: 0.1s ease-in;
  transform: scale(1.1);
}