.basisInfoCard {
  height: 100%;
  width: 100%;
  padding: 0;
  overflow-y: hidden;
  overflow-x: hidden;
}

.basisInfoCardBody {
  padding: 0.5rem;
  overflow-y: hidden;
  overflow-x: hidden;
}

.windowCloseIcon {
  cursor: pointer;
  float: right;
}

.formContainer {
  width: 100%;
  height: 100%;
  padding: 1rem;
  margin: 0rem;
  border: 1px solid rgba(var(--bs-secondary-rgb));
  border-radius: 10px;
}

.formColHeader {
  padding-bottom: 1em;
}

.overviewCol {
  margin: 0 auto;
  padding-bottom: 2em;
}

@media (max-width: 1199px) {
  .overviewCol {
    height: 100%;
  }
}

@media (max-width: 1199px) {
  .tabFormsCol {
    height: 100%;
  }
}

.oversiktHeader {
  margin: 0 auto;
  padding-left: 1em;
  padding-bottom: 0.2em;
}

.overviewContainer {
  height: calc(100vh - 25%);
  overflow-y: auto;
  overflow-x: hidden;
  margin: 0 auto;
  padding-left: 1em;
  padding-right: 1em;
}

.cardHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}

.headerInfoContainer,
.headerActionsContainer {
  margin: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
}

.headerInfoContainer,
.headerActionsContainer>* {
  margin-right: 0.5em;
}

.enehtNameHeader {
  padding-right: 0.3em;
  margin: 0;
}

.toggleBtnContainer {
  width: fit-content;
}

.reactSwitchBtn {
  float: right;
  line-height: 10px;
}

.headerEnhetNameContainer {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.headerBadge {
  padding-bottom: 0.2em;
  margin: 0;
}

.toggleKomplettContainer {
  display: flex;
  /* display: flex; */
  align-items: center;
}