.optionsItemContainer {
  margin: 0;
}

.itemDesc {
  font-size: 13px;
}

.optionSubText {
  display: flex;
  align-items: center;
  font-size: 10px;
}

.icons {
  padding-right: 0.3em;
}

.filterContainer {
  margin-top: 0.3em;
  display: flex;
}

.activeCheckbox {
  margin: 0;
  padding-right: 0.4em;
}