.enhetSearchWrapper {
    width: 100%;
    margin: 0 auto;
}

.possibleEnhetContainer {
    display: flex;
}

.styleLopenummer{
    padding: 0;
    margin-left: 3px;
    margin-bottom: 1px;
    padding-bottom: 0.25rem;
}