.headerContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 1em;
}

.paddingBottom {
  padding-bottom: 1em;
}
