.grossistnrColumn {
  cursor: pointer;
  display: flex;
  justify-content: space-between;
}

.kortnavnColumn {
  cursor: pointer;
  display: flex;
  justify-content: space-between;
}

.nedlagtColumn {
  cursor: pointer;
  display: flex;
  justify-content: space-between;
}

.grossistColumn {
  cursor: pointer;
  display: flex;
  justify-content: space-between;
}

.columnHeader {
  padding: 0;
  margin: 0 auto;
}

.sortableColumnHeader {
  padding: 0;
  margin: 0;
}

.goToEnhetLinkBtn {
  float: right;
}