.titleContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 2em;
}

.cardCurrentTitle {
  margin: 0;
  color: rgba(var(--bs-success-rgb));
}

.cardFutureTitle {
  margin: 0;
  color: rgba(var(--bs-warning-rgb));
}

.buttonToolbar {
  display: flex;
  justify-content: space-between;
}

.kjedehistorikkDates {
  padding: 0.5rem 0;
  font-style: italic;
}
