.radioBtnContainer {
  display: flex;
  flex-direction: column;
}

.modalFooter {
  display: flex;
}

.closeModalBtn {
  margin-right: 0.3em;
}

