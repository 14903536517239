.addToGrupperingRow {
  padding-bottom: 2em;
}

.showAddgrupperingBtn {
  float: right;
}

.spinnerContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
}
