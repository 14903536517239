.noEnheterFeedbackHeader {
  text-align: center;
}

.spinnerContainer {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
