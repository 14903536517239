.btnSubmit {
  float: right;
}

.btnSubmitContainer {
  padding-top: 1em;
  padding-bottom: 5em;
}

.showTableBtn {
  padding-left: 0;
  padding-bottom: 0;
  padding-top: 0;
}

.sisteGrossistCol,
.andelBestillbarChartCol {
  display: flex;
  justify-content: center;
}

.grossistTabRow {
  margin-top: 2em;
  margin-bottom: 2em;
  padding: 1em;
}
