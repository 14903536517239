.knytningSuggestionListGroup {
  width: 100%;
}

.knytningSuggestionListItem {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.enhetgrupperingnavn {
  font-size: 0.875rem;
  color: rgba(var(--bs-success-rgb));
}

.dashSeperator {
  font-size: 0.875rem;
}

.checkbox {
  vertical-align: middle;
}

.showAllBtn {
  font-size: 12px;
}

.suggestionNameEnhetLink {
  padding-left: 0;
}
